import React, { useState, useEffect } from "react";

import Title from "../../components/headlines/Title";
import { Link } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import SubTitle from "../../components/headlines/SubTitle";
import axios from "axios";
import COMPETITORS from "../../fixtures/competitors.js";

const DashboardTopResultsPage = () => {
  const [competitors, setCompetitors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      /*
      const result = await axios(
        "http://public-production-1947173378.us-east-2.elb.amazonaws.com/marketing/recommend/competitors"
      );
      */

      // Fake the API request
      const result = COMPETITORS;
      await new Promise((resolve) => setTimeout(resolve, 500));

      setCompetitors(result.data.competitor);
    };

    fetchData();
  }, []);

  return (
    <div>
      <Title title="Top Results" />
      <Row>
        <Col md={6} className="mb-5">
          <SubTitle title="Top Competitors" />
          <ol>
            {competitors.map((competitor, index) =>
              index < 3 ? (
                <li>
                  <Link to={`./${competitor.id}`}>{competitor.name}</Link>
                </li>
              ) : (
                <></>
              )
            )}
          </ol>
        </Col>
        <Col md={6} className="mb-5">
          <SubTitle title="Top Retweeted" />
          <ol>
            <li>
              <Link to="./ethereum">Ethereum</Link>
            </li>
            <li>
              <Link to="./ethereum">Binance Smart Chain</Link>
            </li>
            <li>
              <Link to="./ethereum">Cardano</Link>
            </li>
            <li>
              <Link to="./ethereum">Polkadot</Link>
            </li>
            <li>
              <Link to="./ethereum">Solana</Link>
            </li>
          </ol>
        </Col>

        <Col md={6} className="mb-5">
          <SubTitle title="Top Keywords" />
          <ol>
            <li>
              <Link to="./ethereum">Ethereum</Link>
            </li>
            <li>
              <Link to="./ethereum">Binance Smart Chain</Link>
            </li>
            <li>
              <Link to="./ethereum">Cardano</Link>
            </li>
            <li>
              <Link to="./ethereum">Polkadot</Link>
            </li>
            <li>
              <Link to="./ethereum">Solana</Link>
            </li>
          </ol>
        </Col>
        <Col md={6} className="mb-5">
          <SubTitle title="Top Shared" />
          <ol>
            <li>
              <Link to="./ethereum">Ethereum</Link>
            </li>
            <li>
              <Link to="./ethereum">Binance Smart Chain</Link>
            </li>
            <li>
              <Link to="./ethereum">Cardano</Link>
            </li>
            <li>
              <Link to="./ethereum">Polkadot</Link>
            </li>
            <li>
              <Link to="./ethereum">Solana</Link>
            </li>
          </ol>
        </Col>

        <Col md={6} className="mb-5">
          <SubTitle title="Top 10 Hashtags" />
          <ol>
            <li>
              <Link to="./ethereum">Ethereum</Link>
            </li>
            <li>
              <Link to="./ethereum">Binance Smart Chain</Link>
            </li>
            <li>
              <Link to="./ethereum">Cardano</Link>
            </li>
            <li>
              <Link to="./ethereum">Polkadot</Link>
            </li>
            <li>
              <Link to="./ethereum">Solana</Link>
            </li>
          </ol>
        </Col>
        <Col md={6} className="mb-5">
          <SubTitle title="Top Liked" />
          <ol>
            <li>
              <Link to="./ethereum">Ethereum</Link>
            </li>
            <li>
              <Link to="./ethereum">Binance Smart Chain</Link>
            </li>
            <li>
              <Link to="./ethereum">Cardano</Link>
            </li>
            <li>
              <Link to="./ethereum">Polkadot</Link>
            </li>
            <li>
              <Link to="./ethereum">Solana</Link>
            </li>
          </ol>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardTopResultsPage;
