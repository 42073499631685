import React from "react";
import Title from "../../components/headlines/Title";
import { Row, Col } from "react-bootstrap";
import Graph from "../../components/graphs/Graph";

const DirectCompetitorsPage = () => {
  const storedCompetitors = JSON.parse(localStorage.getItem("competitors"));

  return (
    <div>
      <Title title="Direct Competitors" />
      <Row>
        <Col md={12}>
          {storedCompetitors.map((competitor, index) => (
            <Graph
              key={index}
              title={index + 1 + ". " + competitor.name}
              competitor={competitor.id}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default DirectCompetitorsPage;
