import React from "react";
import Title from "../../components/headlines/Title";

const DashboardSettingsPage = () => {
  return (
    <div>
      <Title title="Settings" />
    </div>
  );
};

export default DashboardSettingsPage;
