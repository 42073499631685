import React from "react";

import { Form, Button } from "react-bootstrap";
import Title from "../../headlines/Title";

const TargetMarketForm = (props) => {
  return (
    <>
      <Title title="Target Market" />

      <form>
        <Form.Group className="mb-3">
          <Form.Label>Target Regions</Form.Label>
          <Form.Select className="Input">
            <option>- Select all that apply -</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Age Demographic</Form.Label>
          <Form.Select className="Input">
            <option>- Select one -</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Social Channels</Form.Label>
          <Form.Select className="Input">
            <option>- Select all that apply -</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Primary Channel</Form.Label>
          <Form.Select className="Input">
            <option>- Select one -</option>
          </Form.Select>
        </Form.Group>

        <div className="text-center">
          <Button variant="primary" className="Button--primary">
            Save
          </Button>
          <Button
            variant="primary"
            className="Button--primary mx-3"
            onClick={() => props.handleNextClick({ step: 3, progress: 55 })}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
};

export default TargetMarketForm;
