import React from "react";
import { Button } from "react-bootstrap";
import Title from "../../headlines/Title";

const GoalForm = (props) => {
  return (
    <>
      <Title title="Goal" />
      <Button
        className="mb-4"
        variant="outline-secondary"
        onClick={() =>
          props.handleGoalClick({ goal: "brand", step: 1, progress: 15 })
        }
      >
        Brand Awareness
      </Button>

      <Button variant="outline-secondary">Product Awareness</Button>
    </>
  );
};

export default GoalForm;
