import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
//import axios from "axios";

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from "chart.js";

import ENGAGEMENTRATES from "../../fixtures/engagementRates.js";

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title
);

const options = {
  responsive: true,
  color: "#b30868",
  plugins: {
    legend: {
      position: "bottom",
    },
  },

  scales: {
    y: {
      id: "A",
      type: "linear",
      display: true,
      position: "left",
      ticks: {
        color: "#b30868",
      },
      font: {
        color: "#b30868",
      },
      grid: {
        color: "#3e0439",
      },
    },
    y1: {
      id: "B",
      type: "linear",
      display: true,
      position: "right",
      ticks: {
        color: "#b30868",
      },
      grid: {
        drawOnChartArea: false,
        color: "#3e0439",
      },
    },
    x: {
      display: false,
    },
  },
};

const Graph = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      /*
      await axios(
        `http://public-production-1947173378.us-east-2.elb.amazonaws.com/marketing/priceXEngagementRate?account=${props.competitor}&startDate=2021-11-28&endDate=2021-12-02`
      ).then((res) => {
        setData({
          labels: res.data.graph.map((graphData) => graphData.date),
          datasets: [
            {
              yAxisID: "y",
              label: "FOLLOWERS",
              data: res.data.graph.map(
                (graphData) => graphData.twitter_followers_count
              ),
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(25, 32, 68, 0.5)",
              fill: true,
            },
            {
              yAxisID: "y1",
              label: "ENG RATE",

              data: res.data.graph.map(
                (graphData) => graphData.twitter_engagement_rate
              ),
              borderColor: "#7950b6",

              backgroundColor: "rgba(17,4,40,.5)",
              fill: true,
            },
          ],
        });
      });
      */

      // Fake BE Data
      const engagementRates = ENGAGEMENTRATES;
      const rand = Math.floor(Math.random() * 6) + 1;
      const res =
        props.competitor > 6
          ? engagementRates[rand]
          : engagementRates[props.competitor];

      await new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
        if (!res) return;
        setData({
          labels: res.data.graph.map((graphData) => graphData.date),
          datasets: [
            {
              yAxisID: "y",
              label: "FOLLOWERS",
              data: res.data.graph.map(
                (graphData) => graphData.twitter_followers_count
              ),
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(25, 32, 68, 0.5)",
              fill: true,
            },
            {
              yAxisID: "y1",
              label: "ENG RATE",

              data: res.data.graph.map(
                (graphData) => graphData.twitter_engagement_rate
              ),
              borderColor: "#7950b6",

              backgroundColor: "rgba(17,4,40,.5)",
              fill: true,
            },
          ],
        });
      });
    };

    fetchData();
  }, [props.competitor]);

  return (
    <div className="mb-5 Graph">
      <h1>{props.title}</h1>

      <div className="Graph__Container">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default Graph;
