import React from "react";
import Logo from "./ojamu-logo.svg";
import LogoLeft from "./ojamu-logo-left.svg";

const OjamuLogo = (props) => {
  return (
    <img
      className="Logo mb-5"
      src={props.small ? LogoLeft : Logo}
      alt="OJAMU Logo"
    />
  );
};

export default OjamuLogo;
