import React, { useContext, useState } from "react";
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { LoginPage, SignupPage, DashboardPage } from "./pages";
import "./styles/App.scss";
import { UserContext } from "./context/UserContext";

const rootElement = document.getElementById("root");

function PrivateOutlet() {
  const isAuthenticated = authenticateUser();
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

function PublicOutlet() {
  const isAuthenticated = authenticateUser();
  const signedUp = userSignedUp();
  const targetPage = signedUp ? "/dashboard" : "/signup";
  return isAuthenticated ? <Navigate to={targetPage} /> : <Outlet />;
}

// TODO: Fetch user data from BE / Login
function authenticateUser() {
  return localStorage.getItem("user");
}
function userSignedUp() {
  return localStorage.getItem("user") === "user";
}

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicOutlet />}>
          <Route path="" element={<LoginPage />} />
        </Route>
        <Route path="/login" element={<PublicOutlet />}>
          <Route path="" element={<LoginPage />} />
        </Route>
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/dashboard" element={<PrivateOutlet />}>
          <Route
            path="/dashboard"
            element={<DashboardPage page="dashboard" />}
          />
          <Route
            path="/dashboard/direct-competitors"
            element={<DashboardPage page="direct-competitors" />}
          />
          <Route
            path="/dashboard/across-competitors"
            element={<DashboardPage page="across-competitors" />}
          />
          <Route
            path="/dashboard/top-results"
            element={<DashboardPage page="top-results" />}
          />
          <Route
            path="/dashboard/top-results/:resultId"
            element={<DashboardPage page="result-details" />}
          />
          <Route
            path="/dashboard/suggestions"
            element={<DashboardPage page="suggestions" />}
          />
          <Route
            path="/dashboard/more"
            element={<DashboardPage page="more" />}
          />
          <Route
            path="/dashboard/settings"
            element={<DashboardPage page="settings" />}
          />
          <Route
            path="/dashboard/subscriptions"
            element={<DashboardPage page="subscriptions" />}
          />
        </Route>
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
};

render(<App />, rootElement);
