const ENGAGEMENTRATES = {
  1: {
    data: {
      success: "true",
      message: "PriceXEngagementRate graph",
      graph: [
        {
          date: "2021-11-28T00:00:00.000Z",
          twitter_followers_count: "1019239",
          twitter_engagement: "1190",
          price: 7,
          twitter_engagement_rate: 0.0011675377413933336,
        },
        {
          date: "2021-11-29T00:00:00.000Z",
          twitter_followers_count: "1025523",
          twitter_engagement: "313",
          price: 7,
          twitter_engagement_rate: 0.00030521012205479546,
        },
        {
          date: "2021-11-30T00:00:00.000Z",
          twitter_followers_count: "1031529",
          twitter_engagement: "811",
          price: 7,
          twitter_engagement_rate: 0.0007862115364667402,
        },
        {
          date: "2021-12-01T00:00:00.000Z",
          twitter_followers_count: "1037797",
          twitter_engagement: "2269",
          price: 7,
          twitter_engagement_rate: 0.0021863620727367683,
        },
        {
          date: "2021-12-02T00:00:00.000Z",
          twitter_followers_count: "1043459",
          twitter_engagement: "1509",
          price: 7,
          twitter_engagement_rate: 0.00144615169355001,
        },
      ],
    },
  },
  2: {
    data: {
      success: "true",
      message: "PriceXEngagementRate graph",
      graph: [
        {
          date: "2021-11-28T00:00:00.000Z",
          twitter_followers_count: "1897288",
          twitter_engagement: "1",
          price: 7,
          twitter_engagement_rate: 5.270681098494272e-7,
        },
        {
          date: "2021-11-29T00:00:00.000Z",
          twitter_followers_count: "1902074",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
        {
          date: "2021-11-30T00:00:00.000Z",
          twitter_followers_count: "1906887",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
        {
          date: "2021-12-01T00:00:00.000Z",
          twitter_followers_count: "1912359",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
        {
          date: "2021-12-02T00:00:00.000Z",
          twitter_followers_count: "1916222",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
      ],
    },
  },
  3: {
    data: {
      success: "true",
      message: "PriceXEngagementRate graph",
      graph: [
        {
          date: "2021-11-28T00:00:00.000Z",
          twitter_followers_count: "945510",
          twitter_engagement: "781",
          price: 7,
          twitter_engagement_rate: 0.0008260092436885914,
        },
        {
          date: "2021-11-29T00:00:00.000Z",
          twitter_followers_count: "949912",
          twitter_engagement: "479",
          price: 7,
          twitter_engagement_rate: 0.0005042572364597984,
        },
        {
          date: "2021-11-30T00:00:00.000Z",
          twitter_followers_count: "954009",
          twitter_engagement: "1730",
          price: 7,
          twitter_engagement_rate: 0.0018134000832277263,
        },
        {
          date: "2021-12-01T00:00:00.000Z",
          twitter_followers_count: "958380",
          twitter_engagement: "912",
          price: 7,
          twitter_engagement_rate: 0.0009516058348463031,
        },
        {
          date: "2021-12-02T00:00:00.000Z",
          twitter_followers_count: "962137",
          twitter_engagement: "1558",
          price: 7,
          twitter_engagement_rate: 0.0016193120106596046,
        },
      ],
    },
  },
  4: {
    data: {
      success: "true",
      message: "PriceXEngagementRate graph",
      graph: [
        {
          date: "2021-11-28T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
        {
          date: "2021-11-29T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "4",
          price: 7,
          twitter_engagement_rate: 4,
        },
        {
          date: "2021-11-30T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
        {
          date: "2021-12-01T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
        {
          date: "2021-12-02T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
      ],
    },
  },
  5: {
    data: {
      success: "true",
      message: "PriceXEngagementRate graph",
      graph: [
        {
          date: "2021-11-28T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
        {
          date: "2021-11-29T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "4",
          price: 7,
          twitter_engagement_rate: 4,
        },
        {
          date: "2021-11-30T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
        {
          date: "2021-12-01T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
        {
          date: "2021-12-02T00:00:00.000Z",
          twitter_followers_count: "1",
          twitter_engagement: "0",
          price: 7,
          twitter_engagement_rate: 0,
        },
      ],
    },
  },
  6: {
    data: {
      success: "true",
      message: "PriceXEngagementRate graph",
      graph: [
        {
          date: "2021-11-28T00:00:00.000Z",
          twitter_followers_count: "1619469",
          twitter_engagement: "10944",
          price: 7,
          twitter_engagement_rate: 0.006757770602586403,
        },
        {
          date: "2021-11-29T00:00:00.000Z",
          twitter_followers_count: "1626474",
          twitter_engagement: "4953",
          price: 7,
          twitter_engagement_rate: 0.003045237735125185,
        },
        {
          date: "2021-11-30T00:00:00.000Z",
          twitter_followers_count: "1634302",
          twitter_engagement: "7569",
          price: 7,
          twitter_engagement_rate: 0.004631334967466233,
        },
        {
          date: "2021-12-01T00:00:00.000Z",
          twitter_followers_count: "1654604",
          twitter_engagement: "22085",
          price: 7,
          twitter_engagement_rate: 0.013347604623221024,
        },
        {
          date: "2021-12-02T00:00:00.000Z",
          twitter_followers_count: "1662286",
          twitter_engagement: "5492",
          price: 7,
          twitter_engagement_rate: 0.003303883928517716,
        },
      ],
    },
  },
};

export default ENGAGEMENTRATES;
