import React from "react";

import { Form, Button } from "react-bootstrap";
import Title from "../../headlines/Title";

const BrandForm = (props) => {
  return (
    <>
      <Title title="Company" />
      <form>
        <Form.Group className="mb-3">
          <Form.Label>Brand Name</Form.Label>
          <Form.Control className="Input" type="text" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Website</Form.Label>
          <Form.Control className="Input" type="text" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Industry</Form.Label>
          <Form.Select className="Input">
            <option>- Select -</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Size</Form.Label>
          <Form.Select className="Input">
            <option>- Select -</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Revenue</Form.Label>
          <Form.Select className="Input">
            <option>- Select -</option>
          </Form.Select>
        </Form.Group>

        <div className="text-center">
          <Button variant="primary" className="Button--primary">
            Save
          </Button>
          <Button
            variant="primary"
            className="Button--primary mx-3"
            onClick={() => props.handleNextClick({ step: 2, progress: 25 })}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
};

export default BrandForm;
