import React from "react";
import OjamuLogo from "../../components/logo/OjamuLogo";
import { NavLink, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBook,
  faUsers,
  faLightbulb,
  faAward,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";

const DashboardNav = () => {
  let navigate = useNavigate();

  const logOut = () => {
    localStorage.clear("user");
    console.log("logout");
    navigate("/login");
  };

  return (
    <nav className="Nav flex-column">
      <div className="text-center">
        <OjamuLogo small className="Nav__Logo" />
      </div>
      <ul className="Nav__List">
        <li className="Nav__List__Item">
          <NavLink end to="/dashboard">
            <FontAwesomeIcon
              className="Nav__List__Item__Icon"
              icon={faChartPie}
            />
            Dashboard
          </NavLink>
        </li>
        <li className="Nav__List__Item">
          <NavLink to="/dashboard/direct-competitors">
            <FontAwesomeIcon className="Nav__List__Item__Icon" icon={faUsers} />
            Direct Competitors
          </NavLink>
        </li>
        <li className="Nav__List__Item">
          <NavLink to="/dashboard/across-competitors">
            <FontAwesomeIcon className="Nav__List__Item__Icon" icon={faUsers} />
            Across Competitors
          </NavLink>
        </li>
        <li className="Nav__List__Item">
          <NavLink to="/dashboard/top-results">
            <FontAwesomeIcon className="Nav__List__Item__Icon" icon={faBook} />
            Top Results
          </NavLink>
        </li>
        <li className="Nav__List__Item">
          <NavLink to="/dashboard/suggestions">
            <FontAwesomeIcon
              className="Nav__List__Item__Icon"
              icon={faLightbulb}
            />
            Suggestions
          </NavLink>
        </li>
        <li className="Nav__List__Item">
          <NavLink to="/dashboard/more">
            <FontAwesomeIcon className="Nav__List__Item__Icon" icon={faBook} />
            More
          </NavLink>
        </li>
        <li className="Nav__List__Divider"></li>
        <li className="Nav__List__Item">
          <NavLink to="/dashboard/settings">
            <FontAwesomeIcon className="Nav__List__Item__Icon" icon={faCog} />
            Settings
          </NavLink>
        </li>
        <li className="Nav__List__Item">
          <NavLink to="/dashboard/subscriptions">
            <FontAwesomeIcon className="Nav__List__Item__Icon" icon={faAward} />
            Subscriptions
          </NavLink>
        </li>

        <li className="Nav__List__Item">
          <button onClick={logOut}>Sign Out</button>
        </li>
      </ul>
    </nav>
  );
};

export default DashboardNav;
