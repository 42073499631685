import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import ProgressBar from "../../progress/ProgressBar";
import { Container, Row, Col } from "react-bootstrap";
import GoalForm from "./GoalForm";
import BrandForm from "./BrandForm";
import TargetMarketForm from "./TargetMarketForm";
import CompetitorsForm from "./CompetitorsForm";

const SignupForm = () => {
  const initialState = {
    goal: 0,
    step: 0,
    progress: 0,
    isLoading: false,
  };

  const navigate = useNavigate();

  const [signupState, setSignupState] = useState(initialState);

  const onHandleGoalClick = (goal) => {
    setSignupState({ ...signupState, ...goal });
  };

  const onHandleNextClick = (next) => {
    setSignupState({ ...signupState, ...next });
  };

  const onHandleSubmitClick = () => {
    setSignupState({ ...signupState, isLoading: true }); // Just to display the loading indicator
    setTimeout(() => {
      setSignupState({ ...signupState, isLoading: false });
      navigate("/dashboard");
    }, 500);
  };

  const renderSelectedForm = (signupState) => {
    let selectedForm = <GoalForm handleGoalClick={onHandleGoalClick} />;

    const forms = {
      brand: [
        <BrandForm signup={signupState} handleNextClick={onHandleNextClick} />,
        <TargetMarketForm
          signup={signupState}
          handleNextClick={onHandleNextClick}
        />,
        <CompetitorsForm
          signup={signupState}
          handleSubmitClick={onHandleSubmitClick}
          isLoading={signupState.isLoading}
        />,
      ],
    };

    if (signupState.step > 0) {
      selectedForm = forms[signupState.goal]
        ? forms[signupState.goal][signupState.step - 1]
        : null;
    }

    return selectedForm;
  };

  return (
    <>
      <Container className="Container--sm pb-5">
        <Row className="justify-content-center">
          <Col md={5} className="d-flex flex-column">
            {renderSelectedForm(signupState)}
          </Col>
        </Row>
      </Container>
      <ProgressBar width={`${signupState.progress}%`} />
    </>
  );
};

export default SignupForm;
