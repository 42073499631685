import React, { useState, useEffect } from "react";

import { Form, Button, Spinner } from "react-bootstrap";
import Title from "../../headlines/Title";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
//import axios from "axios";
import "react-bootstrap-typeahead/css/Typeahead.css";
import COMPETITORS from "../../../fixtures/competitors.js";

const CompetitorsForm = (props) => {
  const [coin, setCoin] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      /*
      const result = await axios(
        "http://public-production-1947173378.us-east-2.elb.amazonaws.com/marketing/recommend/competitors"
      );
      */

      // Fake the API request
      const result = COMPETITORS;
      await new Promise((resolve) => setTimeout(resolve, 500));

      setCoin(result.data.competitor);
      setFields([
        {
          options: result.data.competitor,
        },
      ]);
    };

    fetchData();
  }, []);

  const filterBy = (option, state) => {
    if (state.selected.length) {
      return true;
    }
    return option.name.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
  };

  const addNewCompetitor = (e) => {
    fields.push({ options: coin });
    console.log("addNewCompetitor", fields);
  };

  const handleChange = (index, e) => {
    const storedCompetitors = JSON.parse(localStorage.getItem("competitors"));
    if (storedCompetitors) {
      storedCompetitors[index] = e[0];
      localStorage.setItem("competitors", JSON.stringify(storedCompetitors));
    } else {
      localStorage.setItem("competitors", JSON.stringify(e));
    }

    return;
  };

  return (
    <>
      <Title title="Competitors" />

      <form>
        <Form.Group className="mb-3">
          <Form.Label>Direct Competitors</Form.Label>
          {fields.map((input, index) => (
            <Typeahead
              className="mb-3"
              filterBy={filterBy}
              id={index}
              key={index}
              labelKey="name"
              options={input.options}
              onChange={(e) => handleChange(index, e)}
            ></Typeahead>
          ))}
        </Form.Group>
        <a href="#" onClick={addNewCompetitor} className="btn-text">
          + add more
        </a>

        <div className="text-center">
          <Button variant="primary" className="Button--primary">
            Save
          </Button>
          <Button
            variant="primary"
            className="Button--primary mx-3"
            onClick={() => props.handleSubmitClick()}
          >
            Submit
            {props.isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              ""
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default CompetitorsForm;
