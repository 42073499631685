import React from "react";

const ProgressBar = (props) => {
  return (
    <div className="ProgressBar">
      <div
        className="ProgressBar__Indicator"
        style={{ width: props.width }}
      ></div>
    </div>
  );
};

export default ProgressBar;
