import React from "react";
import { Line } from "react-chartjs-2";
import SubTitle from "../../components/headlines/SubTitle";

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title
);

const options = {
  responsive: true,
  color: "#b30868",
  plugins: {
    legend: {
      position: "bottom",
    },
  },

  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      ticks: {
        color: "#b30868",
      },
      font: {
        color: "#b30868",
      },
      grid: {
        color: "#3e0439",
      },
    },

    x: {
      display: true,
      ticks: {
        color: "#b30868",
      },
      grid: {
        color: "#3e0439",
      },
    },
  },
};

const labels = ["08.11.2021", "10.11.2021", "12.11.2021", "14.11.2021"];

const data = {
  labels,
  datasets: [
    {
      label: "Polkadot",
      data: [0.84, 0.81, 0.77, 0.44],
      borderColor: "rgb(53, 162, 235)",
      lineTension: 0.4,
      radius: 6,
    },
    {
      label: "Solana",
      data: [1.184, 1.15, 0.8, 0.65],
      borderColor: "#7950b6",
      lineTension: 0.4,
      radius: 6,
    },
  ],
};

const GraphCompetitor = (props) => {
  return (
    <div className="mb-5 Graph d-flex">
      <SubTitle title={props.title} rotate h3 light />
      <Line data={data} options={options} />
    </div>
  );
};

export default GraphCompetitor;
