import React, { useState } from "react";
import OjamuLogo from "../components/logo/OjamuLogo";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import LoginForm from "../components/forms/login/LoginForm";

const LoginPage = ({ onLoginUser }) => {
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const loginUser = (data) => {
    // TODO: Login via BE
    setIsLoading(true);
    axios
      .get("https://shoppingapiacme.herokuapp.com/shopping")
      .then((res) => {
        setUser(res.data);
        if (data.username === "user" && data.password === "test") {
          localStorage.setItem("user", "user");

          return navigate("/dashboard");
        } else {
          localStorage.setItem("user", "hans");
          return navigate("/signup");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Container className="pt-5 Container--sm">
      <Row className="justify-content-center">
        <Col md={5} className="text-center">
          <OjamuLogo />
          <LoginForm onSubmit={loginUser} isLoading={isLoading} />
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
