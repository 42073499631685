const COMPETITORS = {
  data: {
    success: "true",
    message: "OK",
    competitor: [
      { id: 6, name: "Binance Smart Chain" },
      { id: 7, name: "Cardano" },
      { id: 10, name: "Chainlink" },
      { id: 11, name: "Decentraland" },
      { id: 2, name: "Ethereum" },
      { id: 9, name: "Fantom" },
      { id: 4, name: "Marko Muts" },
      { id: 3, name: "Polkadot" },
      { id: 8, name: "Polygon" },
      { id: 5, name: "Rivo Roo" },
      { id: 12, name: "Shib" },
      { id: 1, name: "Solana" },
    ],
  },
};

export default COMPETITORS;
