import React from "react";

const SubTitle = (props) => {
  return (
    <h2
      className={`Headline Headline--glowing mb-2 
      ${props.h3 ? "h3" : ""}
      ${props.light ? "Headline--light" : ""}
      ${props.rotate ? "Headline--rotate" : ""}`}
    >
      {props.title}
    </h2>
  );
};

export default SubTitle;
