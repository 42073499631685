import React from "react";

import OjamuLogo from "../components/logo/OjamuLogo";
import { Container } from "react-bootstrap";
import SignupForm from "../components/forms/signup/SignupForm";

const SignupPage = () => {
  return (
    <>
      <Container fluid className="p-5">
        <OjamuLogo small />
      </Container>

      <SignupForm />
    </>
  );
};

export default SignupPage;
