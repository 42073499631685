import React from "react";

import { Button, Spinner } from "react-bootstrap";
import MetaMask from "../../../metamask.svg";

const LoginForm = ({ onSubmit, isLoading }) => {
  const usernameRef = React.useRef();
  const passwordRef = React.useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      username: usernameRef.current.value,
      password: passwordRef.current.value,
    };
    onSubmit(data);
  };

  return (
    <>
      <form className="d-flex flex-column" onSubmit={handleSubmit}>
        <input
          className="Input mb-3"
          type="text"
          ref={usernameRef}
          placeholder="Email"
        />
        <input
          className="Input"
          type="password"
          ref={passwordRef}
          placeholder="Password"
        />

        <Button type="submit" className="btn Button--primary mt-5">
          Login
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            ""
          )}
        </Button>
        <div className="Divider">
          <span className="Divider__Span">or</span>
        </div>

        <Button className="Button__MetaMask text-start text-white w-100">
          <img
            className="Button__MetaMask--logo"
            src={MetaMask}
            alt="MetaMask Log In"
          />
          <span>Log in with MetaMask</span>
        </Button>
      </form>
    </>
  );
};

export default LoginForm;
