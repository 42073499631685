import React from "react";
import Title from "../../components/headlines/Title";

const DashboardOverviewPage = () => {
  return (
    <div>
      <Title title="Hey there 🦄" />
    </div>
  );
};

export default DashboardOverviewPage;
