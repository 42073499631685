import React from "react";
import Title from "../../components/headlines/Title";

const DashboardSubscriptionsPage = () => {
  return (
    <div>
      <Title title="Subscriptions" />
    </div>
  );
};

export default DashboardSubscriptionsPage;
