import React from "react";

import { Container } from "react-bootstrap";
import DashboardNav from "../../components/dashboard/DashboardNav";
import DirectCompetitorsPage from "./DirectCompetitorsPage";
import DashboardOverviewPage from "./DashboardOverviewPage";
import DashboardTopResultsPage from "./DashboardTopResultsPage";
import DashboardAcrossCompetitorsPage from "./DashboardAcrossCompetitorsPage";
import DashboardSuggestionsPage from "./DashboardSuggestionsPage";
import DashboardMorePage from "./DashboardMorePage";
import DashboardSettingsPage from "./DashboardSettingsPage";
import DashboardSubscriptionsPage from "./DashboardSubscriptionsPage";
import DashboardResultsPage from "./results/DashboardResultsPage";
import ProgressBar from "../../components/progress/ProgressBar";

const dashboardPages = {
  dashboard: <DashboardOverviewPage />,
  "top-results": <DashboardTopResultsPage />,
  "direct-competitors": <DirectCompetitorsPage />,
  "across-competitors": <DashboardAcrossCompetitorsPage />,
  suggestions: <DashboardSuggestionsPage />,
  more: <DashboardMorePage />,
  settings: <DashboardSettingsPage />,
  subscriptions: <DashboardSubscriptionsPage />,
  "result-details": <DashboardResultsPage />,
};

const SignupPage = (props) => {
  return (
    <>
      <Container fluid className="vh-100 Container__Dashboard">
        <DashboardNav />

        <Container className="pt-5 Container--xs">
          {dashboardPages[props.page]}
        </Container>
      </Container>
      <ProgressBar width="100%" />
    </>
  );
};

export default SignupPage;
