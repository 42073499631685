import React from "react";
import Title from "../../components/headlines/Title";
import { Form, Col, Row } from "react-bootstrap";
import GraphCompetitor from "../../components/graphs/GraphCompetitor";

const DashboardAcrossCompetitorsPage = () => {
  return (
    <div>
      <Title title="Across Competitors" />

      <form>
        <Row className="mb-5">
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Select className="Input">
                <option>Solana</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Select className="Input Input--blue">
                <option>Polkadot</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <GraphCompetitor title="% increase of followers" />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default DashboardAcrossCompetitorsPage;
