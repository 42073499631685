import React from "react";

import Title from "../../../components/headlines/Title";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Graph from "../../../components/graphs/Graph";
//import axios from "axios";
import COMPETITORS from "../../../fixtures/competitors.js";

const DashboardResultsPage = (props) => {
  const location = useLocation();
  const competitorID = location.pathname.split("/").pop();

  return (
    <div>
      <Row>
        <Col md={12}>
          <Title
            title={
              COMPETITORS.data.competitor.find((c) => c.id == competitorID).name
            }
          />
          <Graph competitor={competitorID} />
        </Col>
      </Row>
    </div>
  );
};

export default DashboardResultsPage;
