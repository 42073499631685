import React from "react";
import Title from "../../components/headlines/Title";

const DashboardSuggestionsPage = () => {
  return (
    <div>
      <Title title="Suggestions" />
    </div>
  );
};

export default DashboardSuggestionsPage;
